import React from 'react'
import {
  Container,
  Heading,
  Box,
  Button,
  Flex,
  VStack,
  HStack,
  useColorModeValue,
  Text,
} from '@chakra-ui/react'
import { graphql, navigate, Link as GatsbyLink } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../../components/layout'
import Link from '../../../components/link'

import Seo from '../../../components/seo'
import ProductListing from '../../../components/product-listing'
import { FlatBorder } from '../../../components/funky-border'

const CreateOwnGiftBoxProductCard = ({ collection, image }) => {
  const linkHoverColor = useColorModeValue(
    `cardLinkHover`,
    `dark.cardLinkHover`
  )
  const priceColor = useColorModeValue(`brand.gray.900`, `whiteAlpha.900`)
  const addToCartColor = useColorModeValue(
    `brand.dark_darkbrown`,
    `brand.dark_lightgray`
  )
  const linkColor = useColorModeValue(`cardLink`, `dark.cardLink`)

  const productLinkFormatted = `/collections/${collection.handle}`

  const localImage = getImage(image)

  return (
    <Flex mb={12} flexDirection={'column'}>
      <Link
        height={'100%'}
        to={productLinkFormatted}
        aria-label={`View ${collection.title} product page`}
        _hover={{ textDecoration: 'none', h2: { color: linkHoverColor } }}
        _focus={{
          "[data-name='product-image-box']": { boxShadow: 'lg' },
          h2: { color: linkHoverColor },
        }}
      >
        <Box position={'relative'}>
          <GatsbyImage
            image={localImage}
            alt="Build your own gift box bundle"
            formats={['auto', 'webp']}
            placeholder="none"
          />

          <Box
            position={'absolute'}
            width={'100%'}
            padding={'9px'}
            bottom={'0'}
          >
            <Flex
              justifyContent={'space-between'}
              width={'100%'}
              flexDir={'row-reverse'}
            />
          </Box>
        </Box>
      </Link>
      <FlatBorder tags={['vegetarian']} thickness={1} />

      <Flex justifyContent={'space-between'} mt={2} height={'100%'}>
        <Flex
          flexDirection={'column'}
          justifyContent="space-between"
          height={'100%'}
          flexGrow={1}
        >
          <Flex justifyContent={'space-between'} width={'100%'} flexGrow={1}>
            <HStack
              justifyContent={'space-between'}
              height={'100%'}
              alignSelf={'flex-start'}
              flexGrow={1}
            >
              <Box height={'100%'}>
                <Link
                  as={GatsbyLink}
                  width={'100%'}
                  justifyContent={'space-between'}
                  to={productLinkFormatted}
                  aria-label={`View ${collection.title} product page`}
                  _hover={{
                    textDecoration: 'underline',
                    h2: { color: linkHoverColor },
                  }}
                  _focus={{
                    "[data-name='product-image-box']": { boxShadow: 'lg' },
                    h2: { color: linkHoverColor },
                  }}
                  variant="inline"
                >
                  <Heading
                    as="h2"
                    size="md"
                    mb={1}
                    alignSelf="flex-start"
                    color={linkColor}
                    transition="color 0.25s ease-in-out"
                    mr={2}
                  >
                    {collection.title}
                  </Heading>
                </Link>
              </Box>

              <VStack
                height={'100%'}
                justifyContent={'flex-start'}
                flexDirection={'column'}
                justifySelf={'flex-end'}
                alignSelf={'flex-start'}
                gap={0}
              ></VStack>
            </HStack>
          </Flex>
        </Flex>
      </Flex>

      <Box mt={2}>
        <Box
          display="flex"
          fontSize={['lg', 'lg', 'xl', 'xl']}
          pl="0"
          color={priceColor}
          textAlign="right"
        >
          <Text fontWeight="bold">£..</Text>
        </Box>

        <Flex>
          <Button
            to={productLinkFormatted}
            onClick={() => navigate(productLinkFormatted)}
            colorScheme={addToCartColor}
            type={'button'}
            d={'flex'}
            flexGrow={1}
          >
            Choose here
          </Button>
        </Flex>
      </Box>
    </Flex>
  )
}

const CollectionIndex = ({
  data: {
    collection,
    createYourOwnCollection,
    createYourOwnChristmasImage,
    createYourOwnBirthdayImage,
    createYourOwnSpecialImage,
  },
  pageContext: { handle, breadcrumb },
}) => {
  const formattedHandle = handle.replace('-', ' ')
  const includeCreateYourOwn =
    collection.handle.includes('giftboxes') &&
    !collection.handle.includes('merch')
      ? true
      : false

  const descriptionsArray = collection.descriptionHtml.split('<!--split-->')
  // const bottomDescription = collection.descriptionHtml.split('<!--split-->')[1]

  let variationImage

  if (collection.handle.includes('birthday')) {
    variationImage = createYourOwnBirthdayImage
  } else if (collection.handle.includes('christmas')) {
    variationImage = createYourOwnChristmasImage
  } else {
    variationImage = createYourOwnSpecialImage
  }

  return (
    <Layout breadcrumbs={breadcrumb}>
      <Seo
        title={
          collection && collection.seo
            ? collection.seo.title
            : `${formattedHandle} | Mud Foods`
        }
        description={collection && collection.seo && collection.seo.description}
      />

      <Container py={5}>
        <Heading as="h1" textStyle="h1">
          {collection && collection.title ? collection.title : formattedHandle}
        </Heading>
        {descriptionsArray.length > 1 && (
          <Box
            dangerouslySetInnerHTML={{
              __html: descriptionsArray[0],
            }}
          />
        )}

        <ProductListing products={collection.products} childPositionLast={true}>
          {includeCreateYourOwn && (
            <CreateOwnGiftBoxProductCard
              collection={createYourOwnCollection}
              image={variationImage}
            />
          )}
        </ProductListing>

        {descriptionsArray.length > 1 ? (
          <Box
            dangerouslySetInnerHTML={{
              __html: descriptionsArray[1],
            }}
          />
        ) : (
          collection.descriptionHtml && (
            <Box
              dangerouslySetInnerHTML={{
                __html: collection.descriptionHtml,
              }}
            />
          )
        )}
      </Container>
    </Layout>
  )
}

export default CollectionIndex

export const query = graphql`
  query ($handle: String!) {
    createYourOwnCollection: shopifyCollection(
      handle: { eq: "create-your-own-gift-box" }
    ) {
      handle
      seo {
        description
        title
      }
      title
      descriptionHtml
    }
    createYourOwnChristmasImage: file(
      relativePath: { eq: "create-your-own-christmas.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    createYourOwnBirthdayImage: file(
      relativePath: { eq: "create-your-own-birthday.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    createYourOwnSpecialImage: file(
      relativePath: { eq: "create-your-own-special2.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    collection: shopifyCollection(handle: { eq: $handle }) {
      handle
      seo {
        description
        title
      }
      title
      descriptionHtml
      products {
        ...ProductCard
      }
      image {
        originalSrc
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              formats: [AUTO, WEBP]
              quality: 40
              width: 400
            )
          }
        }
      }
    }
  }
`
